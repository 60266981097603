import React, { useState } from "react"
import ReactPlayer from "react-player"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import VideoSlider from "../components/sliders/video-slider"
import VideoSliderSecond from "../components/sliders/video-slider-duplicate"
import BlogSlider from "../components/sliders/video-slider-three-col"
import {
  ArrowDownIcon,
  ArrowLongRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid"
import Uploader from "../components/drop-zone/uploader"
import { useForm } from "react-hook-form"
import firebase from "firebase/compat/app"
import { doc, updateDoc } from "@firebase/firestore"
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage"
import { db, storage } from "../firebaseConfig"
// import { ref, getDownloadURL, uploadBytes } from "@firebase/storage"
// import { storage } from "firebase-functions/v1"
// import {
//   addDoc,
//   arrayUnion,
//   collection,
//   serverTimestamp,
//   updateDoc,
//   doc,
// } from "firebase/firestore"

const Wawanna = ({ data }) => {
  const [formImage, setFormImage] = useState([])
  const [success, setSuccess] = useState()
  const [loading, setLoading] = useState(false)
  const [submitError, setSubmitError] = useState(null)
  const [imageLoading, setImageLoading] = useState(false)
  let formImageURLs = []

  // console.log("storage", storage)
  // console.log("db", db)

  const getData = file => {
    setFormImage(file)
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  // const submitContactRequest = async data => {
  //   let newData = Object.assign({}, data)
  //   newData.createdAt = firebase.firestore.FieldValue.serverTimestamp()
  //   await db.collection("wawannaContacts").add(newData)
  // }

  function refreshPage() {
    window.location.reload(false)
  }

  const sendGridFirebase = async data => {
    fetch(
      "https://us-central1-watawala-website.cloudfunctions.net/wawannaForm",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ ...data, images: formImageURLs }),
      }
    )
      .then(response => response.json())
      .then(result => {
        console.log(result)
      })
  }

  function fileUpload() {
    console.log(formImage)
  }

  const onSubmit = async data => {
    setLoading(true)
    // captionRef.current.value = ""
    fileUpload()

    try {
      // await submitContactRequest(data)
      for (let i = 0; i < formImage.length; i++) {
        const imageUrl = await uploadImage(
          formImage,
          `images/wawanna-form-images/${data.email}/${Date.now()}`,
          i
        )
        formImageURLs.push(imageUrl)
        setImageLoading(i + 1)
        if (imageLoading == formImage.length - 1) {
          setImageLoading(false)
        }
      }
      // parentState(data)
      // data.push(formImageURLs)
      await sendGridFirebase(data)
      setLoading(false)
      setSuccess(true)
      setTimeout(() => {
        setSuccess(false)
      }, 3000)
      setTimeout(() => {
        refreshPage()
      }, 3000)
      setSubmitError(null)
      setFormImage(null)
      reset()
      console.log("submited")
    } catch (error) {
      setLoading(false)
      setSuccess(false)
      setSubmitError("Submit Error occurred!")
      console.log(error.message)
    }
  }

  const images = {
    fruidBg: convertToBgImage(getImage(data.fruidBg)),
    vegie: convertToBgImage(getImage(data.vegie)),
    apple: getImage(data.apple),
    energy: getImage(data.energy),
    enhance: getImage(data.enhance),
    esteem: getImage(data.esteem),
    apple: getImage(data.apple),
    gardning: getImage(data.gardning),
    money: getImage(data.money),
    rewards: getImage(data.rewards),
    workout: getImage(data.workout),
    gallery_1: getImage(data.gallery_1),
    gallery_2: getImage(data.gallery_2),
    gallery_3: getImage(data.gallery_3),
    gallery_4: getImage(data.gallery_4),
    gallery_5: getImage(data.gallery_5),
    gallery_6: getImage(data.gallery_6),
    gallery_7: getImage(data.gallery_7),
    gallery_8: getImage(data.gallery_8),
    gallery_9: getImage(data.gallery_9),
    gallery_10: getImage(data.gallery_10),
    gallery_11: getImage(data.gallery_11),
    gallery_12: getImage(data.gallery_12),
    gallery_13: getImage(data.gallery_13),
    gallery_14: getImage(data.gallery_14),
    gallery_15: getImage(data.gallery_15),
    gallery_16: getImage(data.gallery_16),
    gallery_17: getImage(data.gallery_17),
    gallery_18: getImage(data.gallery_18),
    gallery_19: getImage(data.gallery_19),
    gallery_20: getImage(data.gallery_20),
    gallery_21: getImage(data.gallery_21),
    //blogs
    blogOne: getImage(data.blogOne),
    blogTwo: getImage(data.blogTwo),
    blogThree: getImage(data.blogThree),
    blogFour: getImage(data.blogFour),
    blogFive: getImage(data.blogFive),
    blogSix: getImage(data.blogSix),
    // work
    workOne: getImage(data.workOne),
    workTwo: getImage(data.workTwo),
    workThree: getImage(data.workThree),
    workFour: getImage(data.workFour),
    workFive: getImage(data.workFive),
    workSix: getImage(data.workSix),
    workSeven: getImage(data.workSeven),
    workEight: getImage(data.workEight),
  }

  const gallery = [
    { image: images.gallery_1 },
    { image: images.gallery_2 },
    { image: images.gallery_3 },
    { image: images.gallery_4 },
    { image: images.gallery_5 },
    { image: images.gallery_6 },
    { image: images.gallery_7 },
    { image: images.gallery_8 },
    { image: images.gallery_9 },
    { image: images.gallery_10 },
    { image: images.gallery_11 },
    { image: images.gallery_12 },
    { image: images.gallery_13 },
    { image: images.gallery_14 },
    { image: images.gallery_15 },
    { image: images.gallery_16 },
    { image: images.gallery_17 },
    { image: images.gallery_18 },
    { image: images.gallery_19 },
    { image: images.gallery_20 },
    { image: images.gallery_21 },
  ]

  const advantages = [
    {
      title: "ආහාර සුරක්ෂිතතාව",
      icon: images.apple,
      description:
        "එදිනෙදා පරිභෝජනයට අවශ්‍ය භෝග තමන් විසින්ම වගා කරගැනීම තුළින් පවුලේ පෝෂණ අවශ්‍යතාවන් මෙන්ම ආහාර සුරක්ෂිතතාව ද සලසා ගැනීමට හැකි වේ.",
    },
    {
      title: "වසවිස නැති නැවුම් ආහාර",
      icon: images.gardning,
      description:
        "ගෙවත්තේ වගා කරන භෝග 100%ක් නැවුම් බවින් යුතුව නෙළා ගැනීමට අවස්ථාව ලැබෙනවා පමණක් නොව වසවිසෙන් තොර සුපිරිසිදු අස්වැන්නක් ද මෙතුළින් ලබා ගත හැකිය.",
    },
    {
      title: "නිරෝගිමත් බව",
      icon: images.enhance,
      description:
        "ගෙවතු වගා කටයුතුවල නියැලීමෙන් හොඳ ව්‍යායාමයක් සිරුරට ලැබෙන අතරම සුපිරිසිදු ආහාර පරිභෝජනය නිසා වඩා නිරෝගිමත් ජීවන රටාවක් ද හිමි වේ.",
    },
    {
      title: "මුදල් ඉතිරි කරයි ",
      icon: images.money,
      description:
        "නැවුම් එළවළු පලතුරු ගෙවත්තෙන්ම ලබා ගැනීමට හැකිවන නිසා ආහාර සඳහා වැයවන මුදලින් සැලකිය යුතු ප්‍රමාණයක් ඉතිරි කරගැනීමට හැකියාව උදා වේ.",
    },
    {
      title: "සිරුරට ව්‍යායාම ",
      icon: images.workout,
      description:
        "දිනකට පැය කිහිපයක් ගෙවතු වගා කටයුතුවල නිරත වීම සිරුරේ අනවශ්‍ය කැලරි දහනයට උපකාරී වන නිසා මනා ශාරීරික යෝග්‍යතාවක් ලබා ගැනීමට ද ගෙවතු වගාව හේතු වේ.",
    },
    {
      title: "බලශක්තිය ඉතිරි කරයි ",
      icon: images.energy,
      description:
        "බිම් මට්ටමේ ගෙවතු වගා කටයුතු වැඩි වැඩියෙන් දිරිමත් කිරීම හරහා මහා පරිමාණයෙන් ආහාර නිෂ්පාදනය සඳහා වැය වන දැවැන්ත බලශක්තියක් ඉතිරි කරගැනීමට ද අවස්ථාව උදා වේ.",
    },
    {
      title: "ආත්ම තෘප්තිය ",
      icon: images.esteem,
      description:
        "ගෙවතු වගාව කායික මෙන්ම මානසික සුවතාව සඳහා ද හොඳ ක්‍රියාකාරකමකි. තමන්ගේ දෑතින්ම වගා කළ භෝග පළදරනු දැකීම හා ඒවා පරිභෝජනය කිරීම ඉහළ ආත්ම තෘප්තියක් ලැබීමට හේතු වන්නකි.",
    },
    {
      title: " ‘වවන්න’ දිනන්න ",
      icon: images.rewards,
      description:
        "    වටවල ‘වවන්න’ වැඩසටහන මඟින් සාර්ථක ගෙවතු වගාවන් තෝරා ජයග්‍රාහකයන් වෙත වටිනා බීජ වර්ග පිරිනැමීමක් ද සිදු කෙරෙන බැවින් ගෙවතු වගාවෙන් ලැබෙන ප්‍රතිලාභයන්ට අමතරව ඔබේ ගෙවතු වගා කටයුතු තවත් වර්ධනය කර ගැනීමට ද අවස්ථාව උදා වේ.",
    },
  ]

  const videoSlides = [
    {
      videoURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/videos%2FWATAWALA%20INFLUENCER_Roshan.mp4?alt=media&token=3d58358a-b465-4282-b701-0bf81d194d48",
      lightURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/images%2Fimage%201.png?alt=media&token=c0f45b08-b993-49a3-9913-b3765a06452b",
    },
    {
      videoURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/videos%2FWATAWALA%20INFLUENCER%20SACHINI.mp4?alt=media&token=48ae1a4e-88dc-425f-8bb7-78775a02bab3",
      lightURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/images%2Fsachini.png?alt=media&token=80d2274b-6f38-498f-bc40-2cb480600f51",
    },
    {
      videoURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/videos%2FWATAWALA%20INFLUENCER%20Travel%20with%20Wife.mp4?alt=media&token=9d573cfa-9add-4f69-a382-edb6e34e2e7e",
      lightURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/images%2Ftravel.png?alt=media&token=c5c782aa-a6b3-4121-9c25-1c9d6a194707",
    },
    {
      videoURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/videos%2FWATAWALA%20INFLUENCER%20DIMUTH.mp4?alt=media&token=a579464f-d0bf-4486-b85e-d89f1b20e51d",
      lightURL:
        "https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/images%2FIMG_0466.JPG?alt=media&token=5e4f3d68-7c84-4d8b-9528-4bd65b1dfd02",
    },
  ]
  const schoolProjects = [
    {
      image: images.workOne,
    },
    {
      image: images.workTwo,
    },
    {
      image: images.workThree,
    },
    {
      image: images.workFour,
    },
    {
      image: images.workFive,
    },
    {
      image: images.workSix,
    },
    {
      image: images.workSeven,
    },
    {
      image: images.workEight,
    },
  ]
  const blogs = [
    {
      image: images.blogOne,
      title: "ගෙවත්තෙන්ම කොම්පෝස්ට් හදාගමු",
      anchor: "/blog/#blog1",
    },
    {
      image: images.blogTwo,
      title: "ගොළුබෙලි හානියෙන් මිදෙන්න",
      anchor: "/blog/#blog2",
    },
    {
      image: images.blogThree,
      title: "වගා හානි කරන කෘමි සතුන් මෙල්ල කරමු",
      anchor: "/blog/#blog3",
    },
    {
      image: images.blogFour,
      title: "ගෙවත්තකට නැතුව බැරි ඔසු පැළ",
      anchor: "/blog/#blog4",
    },
    {
      image: images.blogFive,
      title: "දේශගුණය ගැන හරියට දැනගෙන වගා කරන්න",
      anchor: "/blog/#blog5",
    },
    {
      image: images.blogSix,
      title: "වැසි සමයේ බෝග රැකගන්නේ කොහොමද?",
      anchor: "/blog/#blog6",
    },
  ]

  const [visible, setVisible] = useState(8)
  const [popUp, setPopUp] = useState(false)
  const [active, setActive] = useState()

  return (
    <Layout>
      <Seo title="Wawanna | Watawala" />
      <div>
        <div className=" bg-yellow relative">
          <ReactPlayer
            url="https://firebasestorage.googleapis.com/v0/b/watawala-website.appspot.com/o/Watawala%20Tea%20-%20TVC%2040sec%20_%20Dimuth%20Karunaratne%20_%20Niks%20Moving%20Images.mp4?alt=media&token=ecfd8dc3-abf3-4791-acd9-0bafc510ac10"
            controls={false}
            playing={true}
            muted={true}
            width="100%"
            height="100%"
            playsinline
            pip={false}
            playIcon={false}
            loop
          />
          <div className="h-full w-full bg-[#23211B]/60 absolute top-0 z-10"></div>
        </div>
      </div>
      <section>
        <div className="container md:px-20 px-10 mx-auto py-20">
        <h1 className="hidden">"Discover the Remarkable Teas benefits: Enhancing Mood, Reducing Stress, and Cultivating Well-Being"</h1>
          <div>
            <div className="flex flex-col items-center">
              <StaticImage
                alt="Watawala"
                src="../images/brand/promo logo.svg"
                class="mb-8"
              />
              <div className="font-light text-center max-w-3xl  text-lg">
                රට පුරා පැළකරන අදහසක් යන තේමාව පෙරදැරිව වටවල වෙතින් දියත් කළ
                ‘වවන්න’ ‌ගෙවතු වගා සංකල්පය දීප ව්‍යාප්ත වැඩසටහනක් ලෙස මේ වන
                විටත් සාර්ථකව ක්‍රියාත්මකයි. අනාගත ආහාර අර්බුදයට පිළියමක් ලෙස
                හැකි සෑම බිම්කඩකම භෝග වගා කරමින් ආහාර සුරක්ෂිතතාව සලසා ගැනීම
                වටවල ‘වවන්න’ වැඩසටහනේ අරමුණ වී ඇත.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <BackgroundImage {...images.vegie} className="bg-yellow" alt="Watawala">
          <div className="container md:px-20 px-10 mx-auto py-40">
            <div className="">
              <div className="flex justify-center">
                <div>
                  <div className="flex justify-center">
                    <StaticImage
                      src="../images/icons/pdfIcon.png"
                      alt="Watawala"
                      className="w-20"
                    />
                  </div>
                  <div className="uppercase tracking-widest text-center text-white text-3xl mt-10 mb-5">
                    ගෙවතු වගා අත්වැල
                  </div>
                  <div className="max-w-2xl text-white text-lg font-light text-center">
                    ගෙවතු වගාව පිළිබඳ මූලික කරුණු පහසුවෙන් ‌ඉගෙන ගන්න. අපගේ
                    ගෙවතු වගා අත්වැල පහතින් බාගත කරගන්න.
                  </div>
                  <div className="mt-6 flex justify-center">
                    <a href={"/Brochure.pdf"} download>
                      <button className="text-lg tracking-wide uppercase border border-white text-white hover:text-black hover:border-yellow font-light rounded-full px-6 py-2 hover:bg-yellow duration-200 flex gap-3 items-center cursor-pointer">
                        බාගත කරගන්න
                        <div>
                          <ArrowDownIcon className="h-4 w-4" />
                        </div>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>

      <section>
        <BackgroundImage
          {...images.fruidBg}
          className=" relative overflow-hidden"
          alt="Watawala"
        >
          <div className=" bg-yellow/95">
            <div className="container md:px-20 px-10 mx-auto py-20 ">
              <div className="uppercase text-3xl max-w-md text-center mx-auto mb-20 tracking-widest">
                අප ලැබූ සාඩම්බරවත්වත් වටිනාකම්
              </div>
              <div className="px-0 lg:px-28">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-20 md:gap-10 xl:gap-20">
                  <div>
                    <div className="font-bold text-5xl text-center">35</div>
                    <div className="font-light max-w-sm  text-lg  px-10 mt-3 mx-auto text-center">
                      පාසැල් වැඩමුළු ගණන
                    </div>
                  </div>
                  <div>
                    <div className="font-bold text-5xl text-center">6524</div>
                    <div className="font-light max-w-sm  text-lg  px-10 mt-3 mx-auto text-center">
                      සහභාගී වූ පාසැල් සිසුන් ගණන
                    </div>
                  </div>
                  <div>
                    <div className="font-bold text-5xl text-center">76,445</div>
                    <div className="font-light max-w-sm  text-lg  px-10 mt-3 mx-auto text-center">
                      බෙදා දුන් බීජ පැකට් ගණන
                    </div>
                  </div>
                  {/* <div>
                    <div className="font-bold text-5xl text-center">250+</div>
                    <div className="font-light max-w-sm px-10 mt-3 mx-auto text-center">
                      Lorem ipsum dolor sit amet, consectetur{" "}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="container md:px-20 px-10 mx-auto py-20">
          <div className="flex justify-center">
            <div>
              <div className="flex justify-center">
                <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                  අභිප්‍රේරණ
                </div>
              </div>
              <div className="uppercase text-3xl max-w-sm text-center mx-auto mb-14 tracking-widest">
                ‘වවන්න’ සමඟ මුළු රටටම එකම අරමුණක්
              </div>
            </div>
          </div>
          <div>
            <VideoSlider slides={videoSlides} />
            <div className="flex w-full justify-center mt-10">
              <div className="flex gap-3">
                <div className=" my-custom-pagination-div"></div>
              </div>
            </div>
          </div>
          <div className="max-w-3xl text-lg text-center my-7 mx-auto font-light">
            අනාගත ආහාර අර්බුදයට හොඳ පෙර සූදානමක් ඇති කරගැනීමටත්, ඒ වෙනුවෙන් මනා
            පූර්වාදර්ශයක් රටට දායාද කරන්නටත් වටවල ‘වවන්න’ ගෙවතු වගා වැඩසටහන
            සමඟින් බොහෝමයක් දෙනා අත්වැල් බැඳගෙන සිටියි. මේ වන විට බොහෝ දෙනෙකුගේ
            එදිනෙදා ජීවන චර්යාවේ අංගයක් බවට ගෙවතු වගාව පත් කරන්නට ලැබීම අප ලැබූ
            ජයග්‍රහණයකි.
          </div>
          <div className="flex justify-center">
            <Link href="#competition">
              <button className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200">
                ඔබේ ගෙවත්ත පෙන්වන්න
              </button>
            </Link>
          </div>
        </div>
      </section>
      <section>
        <BackgroundImage
          {...images.fruidBg}
          className=" relative overflow-hidden"
          alt="Watawala"
        >
          <div className=" bg-yellow/50">
            <div className="bg-white/80">
              <div className="container md:px-20 px-10 mx-auto py-20 ">
                <div className="uppercase text-3xl max-w-sm text-center mx-auto mb-20 tracking-widest">
                  ගෙවතු වගාවෙන් ලැබෙන ප්‍රතිලාභ
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5">
                  {advantages.map((item, index) => {
                    return (
                      <div
                        className="bg-white rounded-xl p-8 shadow-lg"
                        key={index}
                      >
                        <div className="text-center">
                          <div className="flex justify-center">
                            <div className="rounded-lg bg-yellow/50 w-24 h-24 relative">
                              <div className="absolute inset-0 flex justify-center items-center">
                                <GatsbyImage
                                  src="Watawala"
                                  image={item.icon}
                                  className="w-14"
                                  alt="Watawala"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="uppercase font-medium text-center mb-5 mt-7 text-2xl tracking-widest sm:px-10">
                          {item.title}
                        </div>
                        <div className="font-light text-lg text-center">
                          {item.description}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="container md:px-20 px-10 mx-auto py-20 overflow-hidden">
          <div className="mb-14">
            <div className="flex justify-center">
              <div>
                <div className="flex justify-center">
                  <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                    වැඩමුළු සියල්ල
                  </div>
                </div>
                <div className="uppercase text-3xl text-center mx-auto mb-4 tracking-widest">
                  අප විසින් දියත් කළ පාසැල් වැඩමුළු
                </div>
                <div className="max-w-6xl text-center mb-7 mx-auto font-light text-lg">
                  රට පුරා පැළ කරන අදහසක් තේමාව යටතේ ක්‍රියාත්මක ‘වවන්න’ වැඩසටහනේ
                  එක් පියවරක් ලෙස තෝරාගත් පාසැල්වල ආරම්භ කරන වගා ව්‍යාපෘති මාස
                  3ක කාලයක් පුරා අධීක්ෂණයට ලක් කෙරෙන අතර මෙම වගා බිම්වලින් ලබා
                  ගන්නා අස්වනු වටවල සමාගම විසින් මිලදී ගෙන ප්‍රදේශයේ දිළිඳු
                  පවුල් වෙත නොමිලේ බෙදා දීමද සිදු කරනු ලබයි.
                </div>
              </div>
            </div>

            <div className="flex justify-center">
              <Link href="/blog">
                <button className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200">
                  තවත් හදාරන්න
                </button>
              </Link>
            </div>
          </div>
          <div>
            <div>
              <VideoSliderSecond slides={schoolProjects} />
              <div className="flex w-full justify-center mt-10">
                <div className="flex gap-3">
                  <div className="my-custom-pagination-div-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container md:px-20 px-10 mx-auto py-20">
          <div className="mb-14">
            <div className="  w-full">
              <div>
                <div className="flex">
                  <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                    විශේෂාංග
                  </div>
                </div>
                <div className="md:flex justify-between items-end">
                  <div className="uppercase text-3xl max-w-xs pr-10 mb-4 tracking-widest">
                    ඔබේ ගෙවතු වගාවට උපදෙස් සහ අදහස්
                  </div>
                  <div>
                    <div className="flex md:justify-center">
                      <button className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200">
                        සියල්ල නරඹන්න
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <BlogSlider slides={blogs} />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container md:px-20 px-10 mx-auto py-20">
          <div className="flex justify-center">
            <div>
              <div className="flex justify-center">
                <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                  ගැලරිය
                </div>
              </div>
              <div className="uppercase text-2xl max-w-sm text-center mx-auto mb-14 tracking-widest">
                ‘වවන්න’ ව්‍යාපෘතියේ පින්තූර ගැලරිය
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-2  md:grid-cols-4 gap-4">
              {gallery.slice(0, visible).map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      onClick={() => {
                        setPopUp("true")
                        setActive(index)
                      }}
                    >
                      <GatsbyImage
                        alt="Watawala"
                        image={item.image}
                        className="cursor-pointer rounded-lg"
                      />
                    </div>
                    {popUp && (
                      <div className="relative">
                        <div className="bg-black/10 fixed z-50  top-0 left-0 w-full h-screen">
                          <XMarkIcon
                            className="absolute top-0 m-5 right-0 z-40 w-8 text-white cursor-pointer"
                            onClick={() => setPopUp(false)}
                          />
                          <div className="flex items-center   justify-center  h-screen">
                            
                            <GatsbyImage 
                              alt="Watawala"
                              image={gallery[active].image}
                              className="w-1/2 "
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
          <div className="flex justify-center mt-7">
            <button
              className=" tracking-wide uppercase border border-black font-light rounded-full px-6 py-2 hover:bg-yellow hover:border-yellow duration-200"
              onClick={() => {
                setVisible(visible + 4)
              }}
            >
              <div className="">තවත් බලන්න</div>
            </button>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-black/10" id="competition">
          <div className="container md:px-20 px-10 mx-auto py-20">
            <div className="flex justify-center">
              <div>
                <div className="flex justify-center">
                  <div className="px-4 py-1 uppercase mb-4 bg-yellow/50 rounded-full text-brown font-thin">
                    තරඟාවලියට ඇතුළත් වන්න
                  </div>
                </div>
                <div className="uppercase text-2xl max-w-sm text-center mx-auto mb-14 tracking-widest">
                  ඔබේ ගෙවත්තේ ඡායාරූප අපට එවා තෑගි දිනන්න
                </div>
              </div>
            </div>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="text-sm mb-10">
                  ඔබේ නම
                  <span className="text-red-400">*</span>
                  <input
                    type="text"
                    className="border border-black p-3 focus:outline-none rounded-full w-full bg-white mt-3"
                    placeholder="ඔබේ නම ඇතුළත් කරන්න / Enter your name"
                    {...register("name", {
                      required: true,
                    })}
                    name="name"
                  />
                  {errors.name ? (
                    <>
                      {errors.name.message ? (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            {errors.name.message}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            නම හිස් විය නොහැක / Name cannot be empty
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="text-sm mb-10">
                  ඔබේ ඊමේල් ලිපිනය <span className="text-red-400">*</span>
                  <input
                    type="text"
                    className="border border-black p-3 focus:outline-none rounded-full w-full bg-white mt-3"
                    placeholder="ඔබේ ඊමේල් ලිපිනය ඇතුළත් කරන්න / Enter your email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message:
                          "විද්‍යුත් තැපෑල වලංගු නැත / Email address is not valid",
                      },
                    })}
                    name="email"
                  />
                  {errors.email ? (
                    <>
                      {errors.email.message ? (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            {errors.email.message}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            විද්‍යුත් තැපෑල හිස් විය නොහැක / Email cannot be
                            empty
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="text-sm mb-10">
                  ඔබේ ලිපිනය <span className="text-red-400">*</span>
                  <input
                    type="text"
                    className="border border-black p-3 focus:outline-none rounded-full w-full bg-white mt-3"
                    placeholder="ඔබේ ලිපිනය ඇතුළත් කරන්න / Enter your address"
                    name="address"
                    {...register("address", {
                      required: true,
                    })}
                  />
                  {errors.address ? (
                    <>
                      {errors.address.address ? (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            {errors.address.address}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="text-xs text-red-400 font-firs-light mt-1">
                            ලිපිනය හිස් විය නොහැක / Address cannot be empty
                          </div>
                        </>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="text-sm mb-10">
                  ඔබේ දුරකතන අංකය
                  <input
                    type="text"
                    className="border border-black p-3 focus:outline-none rounded-full w-full bg-white mt-3"
                    placeholder="ඔබේ දුරකතන අංකය ඇතුළත් කරන්න / Enter your mobile"
                    name="number"
                    {...register("number", {})}
                  />
                </div>
                <div className="text-sm mb-10">
                  ඔබේ ගෙවතු වගාවේ පෙර සහ පසු තත්ත්වයන් දැක්වෙන ඡායාරූප මෙතැනින්
                  අමුණන්න
                  <Uploader getData={getData} />
                </div>
                <div className="uppercase font-light text-sm tracking-widest">
                  <div className="-mt-10 flex justify-start">
                    <button
                      type="submit"
                      className=" tracking-wide uppercase border border-yellow bg-yellow text-black  font-light rounded-full px-6 py-2 hover:border-black duration-200 flex gap-3 items-center cursor-pointer"
                    >
                      {loading ? (
                        <>
                          <div className="flex items-center gap-2">
                            <Spinner />
                            <div className="text-xs">
                              {imageLoading && (
                                <>Uploading image {imageLoading}</>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <> ඇතුළත් කරන්න</>
                      )}
                      <div>
                        <ArrowLongRightIcon className="h-4 w-4" />
                      </div>
                    </button>
                  </div>
                  {success && (
                    <div className="p-4 bg-emerald-500 text-center mt-5 rounded-full text-white">
                      Your submission has been sent.
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Wawanna

export const uploadImage = async (file, path, i) => {
  const storageRef = ref(storage, path)
  const snapshot = await uploadBytes(storageRef, file[i])
  return getDownloadURL(snapshot.ref)
}

const Spinner = () => {
  return (
    <>
      <svg
        role="status"
        className="inline w-7 h-7 text-white animate-spin dark:text-white/50 fill-white/50 dark:fill-white"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </>
  )
}

export const query = graphql`
  query WawannapageImages {
    fruidBg: file(relativePath: { eq: "backgrounds/fruits.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    vegie: file(relativePath: { eq: "backgrounds/vegie.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    apple: file(relativePath: { eq: "icons/apple.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    energy: file(relativePath: { eq: "icons/energy.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    enhance: file(relativePath: { eq: "icons/enhance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    esteem: file(relativePath: { eq: "icons/esteem.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gardning: file(relativePath: { eq: "icons/gardning.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    money: file(relativePath: { eq: "icons/money.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    rewards: file(relativePath: { eq: "icons/rewards.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workout: file(relativePath: { eq: "icons/workout.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_1: file(relativePath: { eq: "watawala_gallery/1.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_2: file(relativePath: { eq: "watawala_gallery/2.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_3: file(relativePath: { eq: "watawala_gallery/3.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_4: file(relativePath: { eq: "watawala_gallery/4.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_5: file(relativePath: { eq: "watawala_gallery/5.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_6: file(relativePath: { eq: "watawala_gallery/6.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_7: file(relativePath: { eq: "watawala_gallery/7.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_8: file(relativePath: { eq: "watawala_gallery/8.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_9: file(relativePath: { eq: "watawala_gallery/9.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_10: file(relativePath: { eq: "watawala_gallery/10.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_11: file(relativePath: { eq: "watawala_gallery/11.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_12: file(relativePath: { eq: "watawala_gallery/12.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_13: file(relativePath: { eq: "watawala_gallery/13.JPG" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_14: file(relativePath: { eq: "watawala_gallery/14.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_15: file(relativePath: { eq: "watawala_gallery/15.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_16: file(relativePath: { eq: "watawala_gallery/16.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_17: file(relativePath: { eq: "watawala_gallery/17.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_18: file(relativePath: { eq: "watawala_gallery/18.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_19: file(relativePath: { eq: "watawala_gallery/19.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_20: file(relativePath: { eq: "watawala_gallery/20.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    gallery_21: file(relativePath: { eq: "watawala_gallery/21.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogOne: file(relativePath: { eq: "pages/blog/blogOne.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogTwo: file(relativePath: { eq: "pages/blog/blogTwo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogThree: file(relativePath: { eq: "pages/blog/blogThree.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogFour: file(relativePath: { eq: "pages/blog/blogFour.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogFive: file(relativePath: { eq: "pages/blog/blogFive.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blogSix: file(relativePath: { eq: "pages/blog/blogSix.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workOne: file(relativePath: { eq: "pages/school-work/workOne.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workTwo: file(relativePath: { eq: "pages/school-work/workTwo.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workThree: file(relativePath: { eq: "pages/school-work/workThree.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workFour: file(relativePath: { eq: "pages/school-work/workFour.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workFive: file(relativePath: { eq: "pages/school-work/workFive.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workSix: file(relativePath: { eq: "pages/school-work/workSix.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workSeven: file(relativePath: { eq: "pages/school-work/workSeven.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    workEight: file(relativePath: { eq: "pages/school-work/workEight.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`
